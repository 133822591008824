<template>
    <div class="option-list-settings">
        <template v-if="path.length">
            <Button
                type="text"
                @click="openGroup(null)"
                icon="keyboard_arrow_left"
                class="option-list-settings__back-btn"
                >Back</Button
            >
            <div class="option-list-settings__group-heading">
                <span>{{ this.$t(`settings.${path[path.length - 1]}.value`) | capitalize }}</span>
            </div>
        </template>
        <template v-if="!isLoading">
            <template v-for="(group, name) in groups">
                <div
                    :key="name"
                    v-if="name === 'scheme'"
                    class="option-list-settings__item-field option-list-settings__theme-select"
                >
                    <FormItem :name="name" v-bind="group.options">
                        <Select
                            :is="group.component || group.type"
                            v-model="group.value"
                            :name="name"
                            :group="name"
                            v-bind="group.options"
                        >
                            <template v-slot:title-prepend="{ item }">
                                <color-scheme-preview
                                    :scheme="item.value"
                                    v-if="item.value !== 'Custom'"
                                ></color-scheme-preview>
                            </template>
                            <template v-slot:item="{ item }">
                                <color-scheme-preview
                                    :scheme="item.value"
                                    v-if="item.value !== 'Custom'"
                                ></color-scheme-preview>
                                <span>{{ item.translation }}</span>
                            </template></Select
                        >
                    </FormItem>
                </div>
                <SettingsGroup
                    v-else
                    :key="name + itemsType"
                    :type="itemsType"
                    @input="updateSetting"
                    @open-group="openGroup"
                    :group="group"
                    :name="name"
                    :title-icon="icons[name]"
                >
                </SettingsGroup>
            </template>
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SettingsGroup from '@/components/builder/optionList/settings-group';
import Button from '@/components/common/Button';
import FormItem from '@/components/form/item';

import settingsStylesMap from '@/components/builder/optionList/settingsStylesMap';
import convertCSSVariables from '@/configs/color-schemas/style.helper';
import { debounce } from 'lodash';
import * as colorSchemes from '@/configs/color-schemas';
import validateField from '@/helpers/validator';
import Select from '@/components/form/controls/Select';
import ColorSchemePreview from '@/components/common/ColorSchemePreview';

export default {
    name: 'settings',
    components: { ColorSchemePreview, Select, Button, SettingsGroup, FormItem },
    computed: {
        ...mapState('builder', {
            isHistory: 'isHistory',
        }),
        ...mapGetters('builder', {
            current: 'getSidebarRightCurrent',
        }),
        groups() {
            if (this.path[0] === 'styles') {
                return this.mapStylesFromConfig(settingsStylesMap, this.current.styles);
            }
            if (this.path.length) {
                return this.path.reduce((acc, item) => {
                    acc[item] = this.current[item];
                    return acc;
                }, {});
            }
            return this.links.reduce((acc, item) => {
                acc[item] = this.current[item];
                return acc;
            }, {});
        },
    },
    data() {
        return {
            path: [],
            itemsType: 'link',
            isLoading: false,
            links: ['common', 'ecommerce', 'header', 'footer', 'styles', 'socials', 'media', 'language', 'additional'],
            icons: {
                common: 'settings',
                ecommerce: 'shopping_cart',
                header: 'border_top',
                footer: 'border_bottom',
                styles: 'tune',
                socials: 'thumb_up',
                media: 'article',
                language: 'translate',
                additional: 'more_horiz',
            },
        };
    },
    methods: {
        ...mapActions('builder', {
            updateSettings: 'updateSettings',
        }),
        openGroup(group) {
            this.isLoading = true;
            if (group) {
                if (group === 'styles') {
                    this.itemsType = 'dropdown-primary';
                } else {
                    this.itemsType = null;
                }
                this.path.push(group);
            } else {
                this.itemsType = 'link';
                this.path = [];
            }
            this.$emit('change-group', group);
            this.isLoading = false;
        },

        updateSettingsDebounced: debounce(function(newSettings) {
            this.updateSettings(newSettings);
        }, 400),

        mapStylesFromConfig(map, config) {
            const result = {};
            for (const item in map) {
                if (typeof map[item] === 'string') {
                    result[item] = config[map[item]];
                } else {
                    result[item] = this.mapStylesFromConfig(map[item], config);
                }
            }
            return result;
        },

        updateSetting({ value, name, group }) {
            const propertyName =
                ['common', 'system', 'accent', 'fonts', 'generals', 'buttons', 'cart'].includes(group) ||
                this.path[0] !== 'styles'
                    ? name
                    : `${group}_${name}`;
            let target = this.current;
            this.path.forEach((item) => {
                target = this.current[item];
            });
            target[propertyName].value = value;
            this.onUpdateFieldValue(target[propertyName]);
        },
        onUpdateFieldValue: debounce(function(option) {
            if (!option || !option.options) return;

            let errors = [];
            const isValueArray = option.type === 'array' && Array.isArray(option.value);
            if (option.options.validateFunction) {
                if (isValueArray) errors = option.value.map((value) => option.options.validateFunction(value));
                else errors = option.options.validateFunction(option.value);
            } else {
                if (isValueArray) errors = option.value.map((value) => validateField(value, option.options.rules));
                else errors = validateField(option.value, option.options.rules);
            }
            //костыли для очистки пустых массивов ошибок, чтобы не посылались лишние группы настроек для сохранения
            let item = option.options;
            if (errors.length) this.$set(item, 'errors', errors);
            else this.$delete(item, 'errors');
        }, 1000),
    },
    watch: {
        'current.styles.font_family_head.value'(newFamily) {
            this.updFontFamily('font_style_head', newFamily);
        },
        'current.styles.font_family_body.value'(newFamily) {
            this.updFontFamily('font_style_body', newFamily);
        },
        'current.styles.scheme.value'(newSheme) {
            console.log('current.styles.scheme.value');
            if (newSheme !== 'Custom') {
                const newShemeKey = newSheme.toLowerCase().replace(/\s/g, '');
                const newTheme = convertCSSVariables(colorSchemes[newShemeKey]);
                Object.keys(newTheme).forEach((color) => {
                    if (this.current.styles[color]) {
                        this.current.styles[color].value = newTheme[color];
                    }
                });
            }
        },
        current: {
            handler(newSettings) {
                if (this.isHistory) this.updateSettingsDebounced(newSettings);
                this.path = [...this.path];
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
.option-list-settings {
    // overflow: hidden;
    contain: paint;
    position: relative;
    margin: 0 -1 * $spacer * 4;
    &__theme-select {
        padding: 16px 16px 0 16px;
        .select__option {
            justify-content: initial;
            gap: 10px;
        }
        .select__field {
            display: flex;
            gap: 10px;
        }
    }

    &__back-btn {
        display: flex;
        justify-content: start;
        padding: 16px;
        width: 100%;
        background-color: var(--v-surface-base);
        position: sticky;
        z-index: 10;
        top: 0;
        margin-top: -16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        border-top: 2px solid rgba(0, 0, 0, 0.12);
    }
    &__group-heading {
        display: flex;
        align-items: center;
        padding: $spacer * 6.5 $spacer * 5;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        span {
            font-weight: 500;
            font-size: 19px !important;
        }
    }
    &__item {
        &-field {
            margin: 0 0 $spacer * 8;

            &:last-child {
                margin: 0;
            }
        }
        &-subgroup {
            &-title {
                text-transform: capitalize;
                border-bottom: 1px solid #cececf;
            }
        }
    }
}
</style>
