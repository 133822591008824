<template>
    <div class="settings-group" :class="groupClasses">
        <button v-if="type" class="settings-group__header" @click="toggleItem(name)">
            <span class="settings-group__header-title"
                ><span class="material-icons-outlined" v-if="titleIcon && type === 'link'">{{ titleIcon }}</span
                >{{
                    $te(`settings.${name}.value`) ? $t(`settings.${name}.value`) : $t(`settings.styles.${name}`)
                }}</span
            >
            <span class="settings-group__header-more material-icons" :class="{ 'is-open': isOpen }">
                {{ icon }}
            </span>
        </button>
        <div v-if="type !== 'link'" class="settings-group__content" :class="{ 'is-open': !type || isOpen }">
            <div class="settings-group__content-wrapper">
                <template v-for="(item, itemName) in groupsData">
                    <div
                        :key="itemName + item.type"
                        v-if="item.component || item.type"
                        v-show="showByCondition(item)"
                        class="option-list-settings__item-field"
                    >
                        <FormItem :name="itemName" v-bind="item.options">
                            <component
                                :is="item.component || item.type"
                                v-model="item.value"
                                :name="itemName"
                                @update-field="handleInput"
                                v-on="getFieldEvents(item.events)"
                                :group="name"
                                :disabled="disabledByHandler(item.options)"
                                v-bind="item.options"
                            ></component>
                        </FormItem>
                    </div>
                    <SettingsGroup
                        v-else
                        :key="itemName"
                        :group="item"
                        @input="
                            handleInput({
                                value: $event.value,
                                name: `${itemName}_${$event.name}`,
                                group: $event.group,
                            })
                        "
                        :name="itemName"
                        :type="getSubgroupType(item, itemName)"
                    ></SettingsGroup>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ColorPicker from '@/components/form/controls/ColorPicker';
import Select from '@/components/form/controls/Select';
import SettingsGroup from '@/components/builder/optionList/settings-group';
import FormItem from '@/components/form/item';
import MenuEditor from '@/components/builder/menuEditor';
import InputAddress from '@/components/form/controls/InputAddress';
import OptionsViewEdit from '@/components/builder/OptionsViewEdit/OptionsViewEdit';
import InputRatio from '@/components/form/controls/InputRatio';
import Color from '@/components/form/controls/ColorPicker';
import { cloneDeep, isEqual } from 'lodash';
import { mapGetters } from 'vuex';

export default {
    name: 'SettingsGroup',
    components: {
        ColorPicker,
        Select,
        SettingsGroup,
        FormItem,
        MenuEditor,
        InputAddress,
        OptionsViewEdit,
        InputRatio,
        Color,
    },

    props: {
        group: { type: [Object, String], default: () => {} },
        type: {
            type: String,
            default: 'link',
            validator(value) {
                return ['dropdown-primary', 'dropdown-secondary', 'link', null].includes(value);
            },
        },
        bottomLevel: {
            type: Boolean,
            default: false,
        },
        titleIcon: {
            type: String,
            default: null,
        },
        name: { type: String },
    },
    created() {
        this.groupsData = cloneDeep(this.group);
    },
    computed: {
        icon() {
            switch (this.type) {
                case 'dropdown-primary':
                    return 'expand_more';
                case 'dropdown-secondary':
                    return this.isOpen ? 'remove' : 'add';
                case 'link':
                    return 'keyboard_arrow_right';
                default:
                    return null;
            }
        },
        groupClasses() {
            const classes = [];
            if (this.isOpen) classes.push('highlight');
            if (this.type) classes.push(`-${this.type}`);
            return classes;
        },
        ...mapGetters('builder', {
            current: 'getSidebarRightCurrent',
        }),
    },
    data() {
        return {
            isOpen: false,
            links: ['common', 'ecommerce', 'header', 'footer', 'styles', 'socials', 'media', 'language', 'additional'],
            groupsData: {},
        };
    },
    methods: {
        toggleItem(group) {
            if (this.type === 'link') {
                this.$emit('open-group', group);
            } else {
                this.isOpen = !this.isOpen;
            }
        },
        getSubgroupType() {
            if (this.type === 'dropdown-primary') return 'dropdown-secondary';
            return 'dropdown-primary';
        },
        handleInput({ value, name }) {
            this.$emit('input', { value, name, group: this.name });
        },
        disabledByHandler(obj) {
            const field = obj.disabledBy ? obj.disabledBy : '';
            if (!field) return false;
            const keys = field.split('.');
            const disabledByField = this.getObjByKeys(keys);

            return field.includes('imageCrop') ? this.isOriginalCropMode(disabledByField) : disabledByField;
        },
        isOriginalCropMode(option) {
            if (!option) return false;
            return option.value === 'original' ? true : false;
        },
        getObjByKeys(keys) {
            let value = this.current;
            if (!Object.keys(this.current).length) return;
            keys.forEach((key) => (value = value[key]));
            return value;
        },
        showByCondition(setting) {
            if (setting.options.showByCondition) {
                return setting.options.showByCondition(this.current);
            }
            return true;
        },
        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.current);
            }
            return fieldEvents;
        },
    },
    watch: {
        group: {
            handler(newVal) {
                if (!isEqual(newVal, this.groupsData)) {
                    this.groupsData = cloneDeep(newVal);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';
@import '@/scss/variables/system-colors.scss';

.settings-group {
    &.highlight {
        background: lighten($item-hover, 2%);
        z-index: 1;
        &__header {
            background: darken($item-hover, 10%);
        }
    }
    &.highlight &__header {
        background: $item-active;
    }
    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 16px;
        min-height: 56px;
        background: none;
        cursor: pointer;
        position: relative;
        transition: $transition-fast;
        border: none;

        &:hover {
            background: #edf5fc;
            .settings-group__header-more,
            .settings-group__header-title {
                transition: $transition-fast;

                color: var(--v-primary-base);
            }
        }

        &-title {
            font-size: 1.1429rem;
            color: #2c2d2e;
            cursor: pointer;
            white-space: nowrap;
            display: flex;
            align-items: center;
            gap: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: auto;
        }

        &-more {
            cursor: pointer;
            color: inherit;
            &.is-open {
                transform: rotate(180deg);
            }
        }
    }

    &__content {
        display: block;
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        transition: $transition-default;

        &.is-open {
            max-height: 7000px;
            opacity: 1;
            pointer-events: auto;
        }

        &-wrapper {
            padding: 16px;
        }
    }
    &.-dropdown-primary {
        .option-list-settings__item-field {
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &.highlight {
            & > .settings-group__header {
                .settings-group__header-title,
                .settings-group__header-more {
                    color: var(--v-primary-base) !important;
                }
            }
        }
        .settings-group__content-wrapper {
            padding: 0;
        }
        .option-list-settings__item-field {
            padding: 0 16px;
            &:last-child {
                padding-bottom: 16px;
            }
            &:first-child {
                padding-top: 16px;
            }
        }
    }
    &.-dropdown-secondary {
        border-bottom: 1px solid #cececf;
        .settings-group {
            &__content,
            &__header {
                background-color: #f5f7fa;
            }
            &__header {
                &:hover {
                    background-color: #f5f7fa;
                    &-more,
                    &-title {
                        color: var(--v-primary-base);
                    }
                }
                &-more {
                    &.is-open {
                        transform: none;
                    }
                }
            }
        }
    }
}
</style>
